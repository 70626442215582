<template>
  <div>
    <div class="content-row gray-bg">
      <div class="content-layout-fixer">
        <div class="head">
          <BrandLogo :width="170" :height="170" :image="partnerImage" />
          <div class="right-area" v-if="headerData">
            <VueHeadline level="2" weightLevel="6" color="grey-40" class="headline-text">
              {{ headerData.title }}
            </VueHeadline>
            <VueText sizeLevel="3" color="grey-30" class="text">
              <div>{{ headerData.description }}</div>
            </VueText>
          </div>
        </div>
        <div class="tabs">
          <div
            :class="activeTab == 'visitDays' ? 'active-title' : ''"
            @click="setActiveTab('visitDays')"
          >
            <VueText
              :color="activeTab === 'visitDays' ? 'currentColor' : 'grey-30'"
              sizeLevel="5"
              weightLevel="3"
              >TESLİMAT GÜNLERİM</VueText
            >
          </div>
          <div
            :class="activeTab == 'orderDays' ? 'active-title' : ''"
            @click="setActiveTab('orderDays')"
          >
            <VueText
              :color="activeTab === 'orderDays' ? 'currentColor' : 'grey-30'"
              sizeLevel="5"
              weightLevel="3"
              >SİPARİŞ GÜNLERİM</VueText
            >
          </div>
        </div>
        <hr class="bottom" />

        <div class="days-table">
          <div class="day" v-for="(day, index) in DAYS" :key="index">
            <div class="day-name">{{ day }}</div>
            <div class="day-check" v-for="(partner, i) in partners" :key="i">
              <VueIcon
                v-if="isChecked(day, partner)"
                :iconName="icons.iconChecked.name"
                :width="icons.iconChecked.width"
                :height="icons.iconChecked.height"
              />
            </div>
          </div>
        </div>
        <div class="items-wrapper">
          <router-link
            class="list-button"
            :to="{ path: priceListButton.path }"
            @click.native="dataLayer"
          >
            <VueIcon
              class="icon-style"
              iconColor="white"
              :iconName="priceListButton.icon.name"
              :width="priceListButton.icon.width"
              :height="priceListButton.icon.height"
            />
            <span>{{ priceListButton.text }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <VueHeadline class="products-headline" level="1" weightLevel="3">Markalar</VueHeadline>
        <div class="products-wrapper">
          <BrandProduct
            v-for="category in categories"
            :key="category.id"
            :productName="category.name"
            :productImageSrc="category.image"
            :productPath="`peyman/${slugify(category.name)}/${category.id}`"
          ></BrandProduct>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandProduct from '@/components/brand/BrandProduct/BrandProduct.vue';
import { Pladis } from '@/services/Api/index.js';
import { User } from '@/services/Api/index';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import { getPartnerImage } from '@/utils/partnerImageUtils.js';
import { slugify } from '@/utils/stringUtils';
import { BUSINESS_PARTNERS } from '@/constants/businessPartners.constants';
import { DAYS } from '@/constants/days.constants';

export default {
  name: 'BusinessPartnersHome',
  mixins: [gtmUtils],
  components: { BrandLogo, VueHeadline, VueText, VueIcon, BrandProduct },
  setup() {
    const activeTab = ref('visitDays');
    const partnerImage = getPartnerImage('peyman');
    const partners = ref([]);
    const headerData = ref(null);
    const categories = ref([]);
    const priceListButton = ref({
      id: 0,
      text: 'Güncel Fiyat Listesi',
      icon: { name: 'IconPriceList', width: 22, height: 26 },
      path: `peyman/price-list`,
    });

    const icons = computed(() => ICON_VARIABLES);

    const setActiveTab = tab => {
      if (activeTab.value !== tab) {
        activeTab.value = tab;
      }
    };

    const isChecked = (day, partner) => {
      if (activeTab.value === 'visitDays') {
        return partner?.routeDays.includes(day);
      } else {
        return partner?.routeOrderDays.includes(day);
      }
    };

    const dataLayer = () => {
      this.pushDataLayerEvent('fiyat_listesi_click', {
        location: 'business-partner',
        partner: 'PEYMAN',
      });
    };

    const getRouteDays = () => {
      User.getRouteDays()
        .then(res => {
          if (res.data && res.data.Data) {
            res.data.Data.forEach(f => {
              if (f.partnerName === BUSINESS_PARTNERS.PEYMAN.partnerName) partners.value = [f];
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
    };

    const getHeader = () => {
      Pladis.getHeader(BUSINESS_PARTNERS.PEYMAN.partnerCode).then(response => {
        if (response.data && response.data.Data) {
          headerData.value = response.data.Data;
        }
      });
    };

    const getCategories = () => {
      Pladis.getCategories(BUSINESS_PARTNERS.PEYMAN.partnerCode).then(response => {
        if (response.data && response.data.Data) {
          categories.value = response.data.Data;
        }
      });
    };

    onMounted(() => {
      getRouteDays();
      getHeader();
      getCategories();
    });

    return {
      activeTab,
      DAYS,
      partnerImage,
      partners,
      headerData,
      categories,
      priceListButton,
      icons,
      setActiveTab,
      isChecked,
      slugify,
      dataLayer,
    };
  },
};
</script>

<style scoped lang="scss">
.content-row {
  height: unset;
  padding-left: 20px;
  padding-bottom: 50px;
}
.gray-bg {
  background: #f8f9f9;
  margin-bottom: 60px;
}
.head {
  display: flex;
  padding: 40px 0 50px 0;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
  .right-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 18px;
    width: 100%;

    .headline-text {
      margin-bottom: palette-space-level(10);
    }
    .text {
      line-height: 1.27;
      padding-right: palette-space-level(10);
    }
  }
}
.list-button {
  margin-top: 40px;
  display: block;
  height: 55px;
  width: max-content;
  line-height: 55px;
  padding: 0 28px;
  text-align: center;
  border-radius: palette-radius-level('15');
  background-image: linear-gradient(to left, #d20051, #ffa300);
  margin-right: 15px;
  text-decoration: none;
  color: #ffffff;
  a {
    color: #ffffff;
    text-decoration: none;
  }
}
.icon-style {
  display: inline-block;
  position: relative;
  left: -10px;
}
.items-wrapper {
  margin-top: palette-space-level(50);
  text-align: -webkit-center;
}
.products-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 21px;
  grid-row-gap: 40px;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
}
.products-headline {
  padding: 0 0 30px 0;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
}
.tabs {
  display: flex;
  z-index: 5;
  & > div {
    padding-bottom: palette-space-level(10);
    margin-right: palette-space-level(40);
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      border-radius: palette-radius('radius-11');
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -3px;
      left: 0;
      background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
      opacity: 0;
      transition: all 0.3s ease;
    }
    &.active-title {
      position: relative;
      &::after {
        opacity: 1;
      }
    }
  }
}
.days-table {
  margin-top: palette-space-level(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  border-left: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
}
.day {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  &-name {
    background-color: palette-color-level('grey', 10);
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    color: palette-color-level('grey', 30);
  }
  &-check {
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
}
</style>
